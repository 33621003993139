<template>
	<div class="container content-themes">
		<div class="page-sub-box clearfix ui-glid-box top-box">
			<div class="fl per50">
				<table class="table_form line-bin">
					<caption>
						<strong>기본 정보</strong>
					</caption>
					<colgroup>
						<col style="width:50%">
						<col style="width:50%">
					</colgroup>

					<thead class="sub_title_txt">
						<tr>
							<td colspan="2">
								<h2>기본 정보</h2>
							</td>
						</tr>
					</thead>
					<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>사이트</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="contentData.siteId"
								width="300px"
								placeholder="사이트 선택"
								:items="infoPushSiteList"
								display-expr="siteNm"
								value-expr="siteId"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>Action</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="contentData.actionId"
								width="300px"
								placeholder="Action 선택"
								:items="actionList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>사용여부</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td>
							<DxSwitch v-model="viewCd"/>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="fl per50">
				<table class="table_form line-bin" style="padding-top: 51px">
					<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>순서</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxNumberBox
								v-model="contentData.contentOrd"
								placeholder="순서"
								width="300px"
								:styling-mode="stylingMode"
								class="mar_ri10"
							>
							</DxNumberBox>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>설명</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								v-model="contentData.description"
								placeholder="설명"
								width="300px"
								:styling-mode="stylingMode"
								class="mar_ri10"
							>
							</DxTextBox>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
		<template v-if="selectedAction !== null">
			<div class="line_bottom_1px"></div>

			<div class="page-sub-box clearfix ui-glid-box top-box">
				<div class="fl per50">
					<table class="table_form line-bin">
						<caption>
							<strong>Action 정보</strong>
						</caption>
						<colgroup>
							<col style="width:50%">
							<col style="width:50%">
						</colgroup>
						<thead class="sub_title_txt">
						<tr>
							<td colspan="2">
								<h2>Action 정보</h2>
							</td>
						</tr>
						</thead>
						<tbody>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>IN/OUT</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								{{ this.$_getCode('infopush_inout').find((code) => code.id === selectedAction.inoutCd).codeNm }}
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>프롤로그 동작</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								{{ this.$_getCode('infopush_prologue').find((code) => code.id === selectedAction.prologueCd).codeNm }}
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>프롤로그 이미지</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.prologueImg ? selectedAction.prologueImg.id : '미사용' }}
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<!--<span>프롤로그 이미지</span>-->
								</label>
							</th>
							<td class="clearfix">
								<img
									v-if="prologueImageSource"
									:src="prologueImageSource"
									style="max-height: 150px; max-width: 300px"
								>
							</td>
						</tr>
						<tr v-if="selectedAction.prologueImg">
							<th scope="row">
								<label for="label5">
									<span>URL</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.prologueImg.url }}
							</td>
						</tr>
						<tr v-if="selectedAction.prologueImg">
							<th scope="row">
								<label for="label5">
									<span>설명</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.prologueImg.description }}
							</td>
						</tr>
						<tr v-if="selectedAction.prologueImg">
							<th scope="row">
								<label for="label5">
									<span>텍스트 사용여부</span>
								</label>
							</th>
							<td class="clearfix">
								{{ (selectedAction.prologueImg.textId1 || selectedAction.prologueImg.textId2 || selectedAction.prologueImg.textId3) ? '사용' : '미사용' }}
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="fl per50" style="padding-top: 51px">
					<table class="table_form line-bin">
						<colgroup>
							<col style="width:50%">
							<col style="width:50%">
						</colgroup>
						<tbody>
						<tr v-if="selectedAction.eventCd">
							<th scope="row">
								<label for="label5">
									<span>Event</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								{{ this.$_getCode('infopush_event').find((code) => code.id === selectedAction.eventCd).codeNm }}
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>에필로그 동작</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix" v-if="selectedAction.epilogueCd">
								{{ this.$_getCode('infopush_epilogue').find((code) => code.id === selectedAction.epilogueCd).codeNm }}
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>에필로그 이미지</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.epilogueImg ? selectedAction.epilogueImg.id : '미사용' }}
							</td>
						</tr>
						<tr>
							<th scope="row"></th>
							<td class="clearfix">
								<img
									v-if="epilogueImageSource"
									:src="epilogueImageSource"
									style="max-height: 150px; max-width: 300px"
								>
							</td>
						</tr>
						<tr v-if="selectedAction.epilogueImg">
							<th scope="row">
								<label for="label5">
									<span>URL</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.epilogueImg.url }}
							</td>
						</tr>
						<tr v-if="selectedAction.epilogueImg">
							<th scope="row">
								<label for="label5">
									<span>설명</span>
								</label>
							</th>
							<td class="clearfix">
								{{ selectedAction.epilogueImg.description }}
							</td>
						</tr>
						<tr v-if="selectedAction.epilogueImg">
							<th scope="row">
								<label for="label5">
									<span>텍스트 사용여부</span>
								</label>
							</th>
							<td class="clearfix">
								{{ (selectedAction.epilogueImg.textId1 || selectedAction.epilogueImg.textId2 || selectedAction.epilogueImg.textId3) ? '사용' : '미사용' }}
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</template>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton text="저 장" :width="120" :height="40" class="default filled txt_S medium" :onClick="() => onSave()" />
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" :onClick="() => $_goPrePage()" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '../../../../plugins/common-lib';

export default {
	components: { DxButton, DxSwitch, DxTextBox, DxSelectBox, DxNumberBox },
	props: {},
	watch: {
		'contentData.siteId': function(newVal) {
			this.getActionList(newVal);
		},
		'contentData.actionId': function(newVal) {
			this.selectedAction = this.actionList.find((action) => action.id === newVal);
		},
		'selectedAction.prologueImg.image': function(newVal) {
			if(newVal) {
				this.prologueImageSource = this.$_getAttachFileURL(null, newVal);
			}
		},
		'selectedAction.epilogueImg.image': function(newVal) {
			if(newVal) {
				this.epilogueImageSource = this.$_getAttachFileURL(null, newVal);
			}
		},
	},
	computed: {},
	data() {
		return {
			infoPushSiteList: [],
			stylingMode: 'outlined',
			contentData: {},
			viewCd: true,
			actionList: [],
			selectedAction: null,
			selectedPrologue: {},
			selectedEpilogue: {},
			prologueImageSource: null,
			epilogueImageSource: null
		}
	},
	methods: {
		async onSave() {
			if(!this.contentData.siteId) {
				this.$_Msg("사이트 선택은 필수 항목입니다.");
				return;
			} else if(!this.contentData.actionId) {
				this.$_Msg("액션 선택은 필수 항목입니다.");
				return;
			} else if(!this.contentData.contentOrd) {
				this.$_Msg("순서는 필수 항목입니다.");
				return;
			} else if(!this.contentData.description) {
				this.$_Msg("설명은 필수 항목입니다.");
				return;
			}

			this.contentData.viewCd = this.viewCd ? this.$_getUsedCode.id : this.$_getUnUsedCode.id;
			const payload = {
				actionname: 'INFOPUSH_CONTENT_UPDATE',
				data: {
					data: [this.contentData]
				}
			}

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				await this.$_Msg('저장되었습니다.');
				this.$_goPrePage();
			}
		},
		async getActionList(siteId) {
			const params = {
				viewCd: this.$_getUsedCode.id
			};

			if(siteId) {
				params.siteId = siteId;
			}

			const payload = {
				actionname: 'INFOPUSH_ACTION_LIST_ALL',
				data: {
					params
				}
			}

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				this.actionList = res.data.data;
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionname: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				if(res.data.data.length) {
					this.infoPushSiteList = res.data.data
				} else {
					this.infoPushSiteList = [];
				}
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			await this.getInfoPushSiteList();
			await this.getActionList();
			if(this.$store.getters.getDetailParams?.sendData) {
				this.contentData = { ...this.$store.getters.getDetailParams.sendData }
			}
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
	destroyed() {},
};
</script>

<style scoped>
.ui-glid-box > div.fl {
    border-right: 0;
}

.top-box {
    height: auto;
}
.top-box > div {
    height: auto;
}

.table_form td > div {
    display: inline-block;
    vertical-align: middle;
}
</style>
